<template>
  <div>
    <div>
      <div class="inputFrom">
        <div class="bianKuang">
          <div class="titleClass">1、性别：</div>
          <div>
            <van-radio-group v-model="idList.Sex"
              v-for="item in sexlist"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">2、年龄(周岁)：</div>
          <div>
            <van-radio-group v-model="idList.Age"
              v-for="item in ridlist"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">3、学历：</div>
          <div>
            <van-radio-group v-model="idList.Edu"
              v-for="item in Edulist"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">4、婚姻状况：</div>
          <div>
            <van-radio-group v-model="idList.Marriage"
              v-for="item in Marriagelist"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">5、居住情况：</div>
          <div>
            <van-radio-group v-model="idList.Live"
              v-for="item in Livelist"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">6、身份：</div>
          <div>
            <van-radio-group v-model="idList.Identity"
              v-for="item in Identitylist"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">7、在业余时间您更倾向于做什么？(业余爱好)</div>
          <div>
            <van-radio-group v-model="idList.Hobby"
              v-for="item in HobbyList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">8、您最需要社区社会组织为您提供何种帮助？(生活服务需求)</div>
          <div>
            <van-radio-group v-model="idList.LifeHelp"
              v-for="item in LifeHelpList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">9、您希望所在社区为您提供哪些生活服务和设施？(生活服务需求)</div>
          <div>
            <van-radio-group v-model="idList.LifeFacilities"
              v-for="item in LifeFacilitiesList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">10、希望社区社会组织提供哪些培训？(生活服务需求)</div>
          <div>
            <van-radio-group v-model="idList.TrainDemand"
              v-for="item in TrainDemandList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">11、您希望接受培训的内容：</div>
          <div>
            <van-radio-group v-model="idList.TrainContent"
              v-for="item in TrainContentList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
          <div class="titleClass"
            v-show="idList.TrainContent == '7'">请输入希望培训的内容：</div>
          <div v-show="idList.TrainContent == '7'">
            <input v-model="idList.OtherTrain"
              type="text"
              placeholder="请输入希望培训的内容" />
          </div>
        </div>

        <div class="bianKuang">
          <div class="titleClass">12、您希望社工还可以为您提供什么类型的服务？</div>
          <div>
            <van-radio-group v-model="idList.SerDemand"
              v-for="item in SerDemandList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
          <div class="titleClass"
            v-show="idList.SerDemand == '9'">请输入其他服务类型：</div>
          <div v-show="idList.SerDemand == '9'">
            <input v-model="idList.OtherService"
              type="text"
              placeholder="请输入其他服务类型" />
          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">13、您是否愿意积极参与社区组织活动？</div>
          <div>
            <van-radio-group v-model="idList.InActivity"
              v-for="item in InActivityList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">14、您希望加入社区志愿者队伍吗？</div>
          <div>
            <van-radio-group v-model="idList.InTeam"
              v-for="item in InTeamList"
              :key="'a'+ item.id">
              <van-radio :name="item.id">{{item.value}}</van-radio>
            </van-radio-group>

          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">15、您对于在社区开展有关于青少年的活动有什么意见或建议？</div>
          <div>
            <input v-model="idList.Idea"
              type="text"
              placeholder="请输入意见或建议" />
          </div>
        </div>
        <div class="bianKuang">
          <div class="titleClass">16、请留下您的联系方式，方便今后为您提供服务：</div>
          <div>
            <input v-model="idList.TouchTel"
              type="text"
              placeholder="请输入联系方式" />
          </div>
        </div>
        <div class="fromButton">
          <van-button type="info"
            @click="postfrom()">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WxSaveYouthQuest,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      ridlist: [
        { "id": 1, "value": "1-18岁以下" },
        { "id": 2, "value": "18-24岁" },
        { "id": 3, "value": "25-29岁" },
        { "id": 4, "value": "30-35岁" },
        { "id": 5, "value": "35岁以上" }
      ],
      Edulist: [
        { "id": 1, "value": "小学及以下" },
        { "id": 2, "value": "初中" },
        { "id": 3, "value": "高中、中专、中技、职高" },
        { "id": 4, "value": "大专" },
        { "id": 5, "value": "大学本科" },
        { "id": 6, "value": "研究生及以上" }
      ],
      Marriagelist: [
        { "id": 1, "value": "未婚" },
        { "id": 2, "value": "已婚" },
        { "id": 3, "value": "离异(丧偶等)" }
      ],
      Livelist: [
        { "id": 1, "value": "独立自住房" },
        { "id": 2, "value": "独立租房" },
        { "id": 3, "value": "与父母一起住" },
        { "id": 4, "value": "合租住房" }
      ],
      Identitylist: [
        { "id": 1, "value": "学生" },
        { "id": 2, "value": "公务员" },
        { "id": 3, "value": "企事业管理人员" },
        { "id": 4, "value": "个体户或私营企业主" },
        { "id": 5, "value": "工人" },
        { "id": 6, "value": "农民" },
        { "id": 7, "value": "农民工" },
        { "id": 8, "value": "普通职员" },
        { "id": 9, "value": "自由职业者" },
        { "id": 10, "value": "其他" }
      ],
      HobbyList: [
        { "id": 1, "value": "文化娱乐" },
        { "id": 2, "value": "运动健康" },
        { "id": 3, "value": "志愿服务" },
        { "id": 4, "value": "学习深造" },
        { "id": 5, "value": "交友聚会" },
        { "id": 6, "value": "居家上网" },
        { "id": 7, "value": "旅行参观" },
        { "id": 8, "value": "其他" }
      ],
      LifeHelpList: [
        { "id": 1, "value": "寻找工作机会" },
        { "id": 2, "value": "组织实施技能培训" },
        { "id": 3, "value": "开展文化娱乐活动" },
        { "id": 4, "value": "提供交友平台" },
        { "id": 5, "value": "法律援助" },
        { "id": 6, "value": "生活救助" },
        { "id": 7, "value": "心理辅导" }
      ],
      LifeFacilitiesList: [
        { "id": 1, "value": "读书学习场所" },
        { "id": 2, "value": "娱乐健身场所" },
        { "id": 3, "value": "托老服务" },
        { "id": 4, "value": "托幼服务" }
      ],
      TrainDemandList: [
        { "id": 1, "value": "一般服务技能培训(厨艺、美容美发等)" },
        { "id": 2, "value": "维修类(电器等)" },
        { "id": 3, "value": "金融投资类(保险代理、证券投资等)" },
        { "id": 4, "value": "外语(英语等)" },
        { "id": 5, "value": "销售类" },
        { "id": 6, "value": "其他" }
      ],
      TrainContentList: [
        { "id": 1, "value": "一般服务技能类(厨艺、美发、美容等)" },
        { "id": 2, "value": "维修类(电器、汽车等)" },
        { "id": 3, "value": "金融投资类(保险代理、证券投资等)" },
        { "id": 4, "value": "电脑IT类(软件编程、电脑应用等)" },
        { "id": 5, "value": "外语类(英语等)" },
        { "id": 6, "value": "销售类" },
        { "id": 7, "value": "其他(请注明)" }
      ],
      SerDemandList: [
        { "id": 1, "value": "进一步的学习机会" },
        { "id": 2, "value": "法律方面的咨询和援助" },
        { "id": 3, "value": "推荐工作" },
        { "id": 4, "value": "社会救助" },
        { "id": 5, "value": "人际关系处理" },
        { "id": 6, "value": "家庭矛盾调解" },
        { "id": 7, "value": "心理辅导" },
        { "id": 8, "value": "倾诉交流" },
        { "id": 9, "value": "其他(请注明)" },
      ],
      InActivityList: [
        { "id": 1, "value": "非常愿意" },
        { "id": 2, "value": "比较愿意" },
        { "id": 3, "value": "无所谓" },
        { "id": 4, "value": "不愿意" }
      ],
      InTeamList: [
        { "id": 1, "value": "非常愿意" },
        { "id": 2, "value": "比较愿意" },
        { "id": 3, "value": "无所谓" },
        { "id": 4, "value": "不愿意" }
      ],
      sexlist: [
        { id: 1, "value": "男" },
        { id: 2, "value": "女" },
      ],
      idList: {
        YQId: 0,
        OpenID: '',
        Sex: '',
        Age: '',
        Edu: '',
        Marriage: '',
        Live: '',
        Identity: '',
        Hobby: '',
        LifeHelp: '',
        LifeFacilities: '',
        TrainDemand: '',
        TrainContent: '',
        OtherTrain: '',
        SerDemand: '',
        OtherService: '',
        InActivity: '',
        InTeam: '',
        Idea: '',
        TouchTel: '',
      },
      idListRId: false,
      idListEdu: false,
      idListMarriage: false,
      idListLive: false,
      idListIdentity: false,
      addshow: false,
      HobbyStatus: false,
      LifeHelpStatus: false,
      LifeFacilitiesStatus: false,
      TrainDemandStatus: false,
      TrainContentStatus: false,
      SerDemandStatus: false,
      InActivityStatus: false,
      InTeamStatus: false,
      idListSex: false,
    };
  },
  created () {
  },
  methods: {

    // 提交
    postfrom: function () {
      console.log(this.idList);
      console.log(getOpenId());
      if (this.idList.Sex == "") {
        Toast.fail("请选择性别");
        return false;
      }
      if (this.idList.Age == "") {
        Toast.fail("请选择年龄");
        return false;
      }
      if (this.idList.Edu == "") {
        Toast.fail("请选择学历");
        return false;
      }
      if (this.idList.Marriage == "") {
        Toast.fail("请选择婚姻状况");
        return false;
      }
      if (this.idList.Live == "") {
        Toast.fail("请选择居住状况");
        return false;
      }
      if (this.idList.Identity == "") {
        Toast.fail("请选择身份");
        return false;
      }
      if (this.idList.Hobby == "") {
        Toast.fail("未选择，在业余时间您更倾向于做什么");
        return false;
      }
      if (this.idList.LifeHelp == "") {
        Toast.fail("未选择，最需要社区社会组织为您提供何种帮助");
        return false;
      }
      if (this.idList.LifeFacilities == "") {
        Toast.fail("未选择，希望所在社区为您提供哪些生活服务和设施");
        return false;
      }
      if (this.idList.TrainDemand == "") {
        Toast.fail("未选择，希望社区社会组织提供哪些培训");
        return false;
      }
      if (this.idList.TrainContent == "") {
        Toast.fail("未选择，希望接受培训的内容");
        return false;
      }
      if (this.idList.SerDemand == "") {
        Toast.fail("未选择，希望社工还可以为你提供什么类型的服务");
        return false;
      }
      if (this.idList.InActivity == "") {
        Toast.fail("未选择，是否愿意积极参与社区组织活动");
        return false;
      }
      if (this.idList.InTeam == "") {
        Toast.fail("未选择，希望加入社区志愿者队伍");
        return false;
      }
      if (this.idList.Idea == "") {
        Toast.fail("请输入对于在社区开展有关于青少年的活动意见或建议");
        return false;
      }
      if (this.idList.TouchTel == "") {
        Toast.fail("请输入您的联系方式");
        return false;
      }
      this.idList.OpenID = getOpenId()
      WxSaveYouthQuest(this.idList).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.push({
              name: "register",
            });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },

    // 年龄选择器
    onidListRId (val) {
      this.idList.Age = val.id;
      this.idList.ageTitle = val.value;
      this.idListRId = false;
    },
    // 学历选择器
    onidListEdu (val) {
      this.idList.Edu = val.id;
      this.idList.EduTitle = val.value;
      this.idListEdu = false;
    },
    // 婚姻状况选择器
    onidListMarriage (val) {
      this.idList.Marriage = val.id;
      this.idList.MarriageTitle = val.value;
      this.idListMarriage = false;
    },
    // 居住状况选择器
    onidListLive (val) {
      this.idList.Live = val.id;
      this.idList.LiveTitle = val.value;
      this.idListLive = false;
    },
    // 身份选择器
    onidListIdentity (val) {
      this.idList.Identity = val.id;
      this.idList.IdentityTitle = val.value;
      this.idListIdentity = false;
    },
    // 在业余爱好选择器
    onHobby (val) {
      this.idList.Hobby = val.id;
      this.idList.HobbyTitle = val.value;
      this.HobbyStatus = false;
    },
    // 生活服务需求选择器
    onLifeHelp (val) {
      this.idList.LifeHelp = val.id;
      this.idList.LifeHelpTitle = val.value;
      this.LifeHelpStatus = false;
    },
    // 生活服务需求
    onLifeFacilities (val) {
      this.idList.LifeFacilities = val.id;
      this.idList.LifeFacilitiesTitle = val.value;
      this.LifeFacilitiesStatus = false;
    },
    // 社会组织提供培训
    onTrainDemand (val) {
      this.idList.TrainDemand = val.id;
      this.idList.TrainDemandTitle = val.value;
      this.TrainDemandStatus = false;
    },
    // 希望接受培训的内容
    onTrainContent (val) {
      this.idList.TrainContent = val.id;
      this.idList.TrainContentTitle = val.value;
      this.TrainContentStatus = false;
    },
    // 类型的服务
    onSerDemand: function (val) {
      this.idList.SerDemand = val.id;
      this.idList.SerDemandTitle = val.value;
      this.SerDemandStatus = false;
    },
    // 愿意积极参与社区组织活动
    onInActivity (val) {
      this.idList.InActivity = val.id;
      this.idList.InActivityTitle = val.value;
      this.InActivityStatus = false;
    },
    onInTeam (val) {
      this.idList.InTeam = val.id;
      this.idList.InTeamTitle = val.value;
      this.InTeamStatus = false;
    },
    onidListSex (val) {
      this.idList.Sex = val.id;
      this.idList.SexTitle = val.value;
      this.idListSex = false;
    },
    addressfrom (value) {
      // 地址
      var a, b, c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + "," + b.text + "," + c.text;
      var address = [addressCode, addressName];
      return address;
    },
  },
};
</script>
<style scoped>
/deep/.van-radio-group {
  padding: 15px !important;
  border-bottom: 1px solid #ebe3e3;
}
</style>
<style>
.inputFrom {
  margin: 15px 4%;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
.bianKuang {
  padding: 5px 5px -1px 5px;
  border: 1px solid #ebe3e3;
  border-radius: 5px;
  margin-bottom: 10px;
}
.titleClass {
  margin-top: 5px;
  color: #fc7d2e;
  font-size: 18px;
  margin-left: 10px;
}
</style>